/* eslint-disable lodash/prefer-lodash-method */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';

import { useRQLFiltersContext } from '~/app/rosters/RQLFiltersContext';
import { isEmpty, filter, get, keys, head, slice, concat, isEqual, find, isNil } from 'lodash-es';
import rql from '~/vendor/rql';
import { useQuery } from '@tanstack/react-query';
import { queries } from '~/queries';

import { rqlExpressionToObject, rqlListToObject, escapeURL } from './utils';

export const useCurrentSegment = () => {
  const { public_id: segmentPublicId } = useParams();

  const queryResult = useQuery({
    ...queries.segments.detail(segmentPublicId),
    enabled: !!segmentPublicId,
  });

  return queryResult.data || {};
};

const getFilterName = (filter) => {
  return head(keys(filter));
};

// Get the list of filter in a RQL object, for example:
// {$and: [{name: 'test'}, {name: 'another'}]} => [{name: 'test'}, {name: 'another'}]
// {name: 'test'} => [{name: 'test'}]
const getFiltersFromRQLObject = (rqlObject) => {
  const logicalOperator = head(keys(rqlObject));
  if (logicalOperator !== '$and' && logicalOperator !== '$or') {
    // This means there is only one filter or none
    return isEmpty(rqlObject) ? [] : [rqlObject];
  }
  return rqlObject[logicalOperator];
};

/**
 * Obtains the current search params which are not being handled
 * in order to keep them in the URL, since they could be used by
 * other hooks or components.
 *
 * @param {string} currentSearch - The current search string
 * @param {Set} validFiltersSet - The set of valid filters
 *
 * @returns {URLSearchParams}
 */
function getOtherUrlSearchParams(currentSearch, validFiltersSet) {
  const searchParams = new URLSearchParams(currentSearch);
  searchParams.delete('page');
  searchParams.delete('page_size');

  // Generate the RQL expression from the current search string
  const { $ordering: orderingField, ...currentRawRqlObject } = rqlExpressionToObject(currentSearch);

  if (orderingField) {
    const orderingFilterExpression = rql({ $ordering: orderingField });
    searchParams.delete(orderingFilterExpression);
  }

  const allRawFiltersList = getFiltersFromRQLObject(currentRawRqlObject);
  const currentRqlFiltersList = filter(allRawFiltersList, (filter) =>
    validFiltersSet.has(getFilterName(filter))
  );
  const currentRqlFiltersObject = rqlListToObject(currentRqlFiltersList);
  const currentRqlFiltersExpression = rql(currentRqlFiltersObject);
  const currentRqlFiltersParams = new URLSearchParams(currentRqlFiltersExpression);

  for (const [key] of currentRqlFiltersParams.entries()) {
    searchParams.delete(key);
  }

  return searchParams;
}

// As the RQL parser does not have a way to ignore invalid filters is necessary
// to set an arg in the hook with the list of valid filters to remove the
// invalid filters before parsing the RQL string.
/**
 * @param {string} defaultOrderingField - The default ordering field
 * @param {string[]} validFilters - The list of valid filters
 * @param {number} defaultPageSize - The default page size
 * @param {number} defaultPage - The default page
 * @param {string} backend - The backend to use
 * @param {string} cacheKey - The cache key
 */
export function useRQLRouteFilters(
  defaultOrderingField,
  validFilters,
  defaultPageSize = 15,
  defaultPage = 1,
  backend = 'url', // 'url' or 'state'
  cacheKey = ''
) {
  const location = useLocation();
  const history = useHistory();
  const segment = useCurrentSegment();
  const {
    getCachedFilters,
    setCachedFilters,
    getCachedExpression,
    setCachedExpression,
    setInitialFiltersUpdated,
    publishEvent,
  } = useRQLFiltersContext();

  // The filterObj should be like this: {filters: [], ordering: 'name', page: 1, pageSize: 15}
  const [filterObj, setFilterObj] = useState(getCachedFilters(cacheKey));
  const [expression, setExpression] = useState(getCachedExpression(cacheKey));

  const updateFilterObj = (newFilterObj) => {
    if (isEqual(filterObj, newFilterObj)) return;
    setFilterObj(newFilterObj);
    if (!isEmpty(cacheKey)) setCachedFilters(cacheKey, newFilterObj);
    publishEvent(cacheKey, 'filtersChange');
  };

  const validFilterNamesSet = new Set(validFilters);

  const updateExpression = (newExpression) => {
    if (isEmpty(newExpression)) {
      updateFilterObj({
        filters: [],
        ordering: defaultOrderingField,
        page: defaultPage,
        pageSize: defaultPageSize,
      });
      return;
    }

    const { $ordering: orderingField, ...rqlObject } = rqlExpressionToObject(newExpression);
    const rawFilters = getFiltersFromRQLObject(rqlObject);
    const pageFilter = find(rawFilters, (filter) => getFilterName(filter) === 'page');
    const pageSizeFilter = find(rawFilters, (filter) => getFilterName(filter) === 'page_size');

    const newFilters = filter(rawFilters, (filter) =>
      validFilterNamesSet.has(getFilterName(filter))
    );

    updateFilterObj({
      filters: newFilters,
      ordering: orderingField || defaultOrderingField,
      page: get(pageFilter, 'page.$eq', defaultPage),
      pageSize: get(pageSizeFilter, 'page_size.$eq', defaultPageSize),
    });
  };

  const addFilter = (filter) => {
    const { filters } = filterObj;
    const newFilters = [...filters, filter];
    updateFilterObj({ ...filterObj, filters: newFilters });
  };

  const removeFilter = (index) => {
    const { filters } = filterObj;
    const newFilters = concat(slice(filters, 0, index), slice(filters, index + 1));
    updateFilterObj({ ...filterObj, filters: newFilters });
  };

  const updateFilter = (index, filter) => {
    const { filters } = filterObj;
    const newFilters = concat(slice(filters, 0, index), filter, slice(filters, index + 1));
    // if the expression will change, is necessary to reset the page
    if (!isEqual(rqlListToObject(filters), rqlListToObject(newFilters))) {
      updateFilterObj({ ...filterObj, filters: newFilters, page: defaultPage });
      return;
    }
    updateFilterObj({ ...filterObj, filters: newFilters });
  };

  const updateFilters = (filters) => updateFilterObj({ ...filterObj, filters });

  const updateOrdering = (fieldName) => updateFilterObj({ ...filterObj, ordering: fieldName });

  const updatePagination = (pageNumber) => updateFilterObj({ ...filterObj, page: pageNumber });

  const updatePageSize = (newPageSize) => updateFilterObj({ ...filterObj, pageSize: newPageSize });

  // If the user is accessing a segment, the filterObj is updated using the expression, otherwise, the URL is used
  useEffect(() => {
    if (!isNil(get(segment, 'expression', null))) {
      updateExpression(segment.expression);
      return;
    }
    if (backend === 'url') {
      const search = isEmpty(location.search) ? '' : location.search.slice(1); // remove the ? symbol
      updateExpression(escapeURL(search));
      if (!isEmpty(search)) {
        setInitialFiltersUpdated(cacheKey);
      }
    } else if (backend === 'state' && isEmpty(getCachedFilters(cacheKey))) {
      updateExpression('');
    }
  }, [segment?.public_id]);

  // Update the URL if the filterObj changes
  useEffect(() => {
    const rqlObject = rqlListToObject(filterObj.filters);

    const rqlExpression = rql({
      ...rqlObject,
      $ordering: filterObj.ordering,
      page: filterObj.page,
      page_size: filterObj.pageSize,
    });

    if (rqlExpression === expression) {
      return;
    }

    if (backend === 'url') {
      const currentSearch = isEmpty(location.search) ? '' : location.search.slice(1);
      const otherUrlSearchParams = getOtherUrlSearchParams(currentSearch, validFilterNamesSet);
      const otherFiltersToKeepExpression = otherUrlSearchParams.toString();

      const newSearchArr = [];
      // This behavior is needed on track insights but breaks segments
      // See https://app.asana.com/1/80306088024047/project/981225105302110/task/1209897634656524
      if (otherFiltersToKeepExpression !== '' && isEmpty(segment)) {
        newSearchArr.push(otherFiltersToKeepExpression);
      }
      if (rqlExpression !== '') {
        newSearchArr.push(rqlExpression);
      }

      const newSearch = newSearchArr.join('&');
      history.replace(`${location.pathname}?${newSearch}`);
    }

    setExpression(rqlExpression);
    setCachedExpression(cacheKey, rqlExpression);
    publishEvent(cacheKey, 'expressionChange', { expression: rqlExpression });
  }, [filterObj.filters, filterObj.ordering, filterObj.page, filterObj.pageSize, location.search]);

  return {
    expression, // Use the expression in the useEffect to handle the filters changes
    /**
     * @type {Record<string, any>}
     */
    filterObj,
    // Instead of updating everything, prefer to use specific functions for the operations
    addFilter,
    removeFilter,
    updateFilter,
    updateFilters, // Update all filters at once
    updateOrdering,
    updatePagination,
    updatePageSize,
    updateExpression,
    updateFilterObj, // Use only if you have a very specific behavior that cannot be handled by the other functions
  };
}
